<template lang="pug">
include ../../configs/template
div
  div.flex-row-sb.wrap.m-1(v-if="!sailorDocument.sailor_id")
    +field-validation('body.sailor_id', 'sailorId', '["required"]')
  div.mx-4
    +select-validation('body.status_document','statusesList(sailorDocument.sailor_id)','status','labelName','["required"]')(item-value="id")
  div
    v-btn(color="success"  @click="dateConfirmation")  {{ $t('save') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { back } from '@/mixins/main'
import { verificationNewAccount, rejectNewAccount } from '@/configs/constants'
import { createNewAccount } from '@/mixins/validationRules.js'

export default {
  name: 'NewAccountsEdit',
  props: {
    sailorDocument: { type: Object, default: () => ({}) },
    getDocuments: { type: Function, default: () => ({}) }
  },
  data () {
    return {
      buttonLoader: false,
      body: {
        sailor_id: this.sailorDocument.sailor_id,
        status_document: this.sailorDocument.status_document.id
      },
      back
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    })
  },
  mounted () {
    this.getBirthDay()
  },
  validations () { return createNewAccount() },
  methods: {
    ...mapActions(['updateNewAccountsById', 'getShortInfoSailor']),
    statusesList (sailorID) {
      let statuses = this.$store.getters.statusChoose('User')
      if (!this.sailorDocument.sailor_id) statuses.push(this.$store.getters.statusById(34))
      statuses = statuses.filter(el => el.id !== rejectNewAccount)
      return sailorID ? statuses : statuses.filter(value => value.id !== 41)
    },

    async saveStatus () {
      const { documentID } = this.$route.params
      this.buttonLoader = true
      if (![this.body.status_document].includes(verificationNewAccount)) {
        delete this.body.sailor_id
      }
      const response = await this.updateNewAccountsById({ body: this.body, documentID })
      this.buttonLoader = false
      if (response.status_document.id === 36) {
        this.$notification.success('rejectedStatement')
      } else if (response.status_document.id === 41) {
        this.$notification.success('verificationAccSuccess')
      }
      this.getDocuments()
      if (response.status_document.id !== 34) back('new-accounts')
    },

    dateConfirmation () {
      if (this.$v.$invalid) return this.$v.$touch()
      if (this.sailorDocument.sailor_id && (this.sailorDocument.status_document.id === verificationNewAccount) &&
        (this.sailorDocument.sailorShortInfo.date_birth !== this.sailorDocument.birthday)) {
        this.$swal({
          title: this.$i18n.t('notSimilarDate'),
          text: this.$i18n.t('continueVerification'),
          icon: 'warning',
          buttons: [this.$i18n.t('cancel'), this.$i18n.t('confirm')],
          dangerMode: true
        }).then((confirmation) => {
          if (confirmation) {
            this.saveStatus()
          }
        })
      } else this.saveStatus()
    },
    async getBirthDay () {
      if (this.sailorDocument.sailor_id) {
        this.sailorDocument.sailorShortInfo = await this.getShortInfoSailor({ sailorId: this.sailorDocument.sailor_id })
      }
    }
  }
}
</script>
